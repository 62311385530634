import type { BreadCrumbItem } from "~/types/shared/breadcrumb";

const rootItem = { name: "Startseite", item: "/" };

export function generateBreadcrumbs(breadcrumbs: BreadCrumbItem[]) {
  const finalCrumbs = breadcrumbs.map((currentCrumb) => {
    return {
      name: currentCrumb.title,
      item: `/${currentCrumb.url.current}`.replaceAll("//", "/"),
    };
  });

  return finalCrumbs.length ? [rootItem, ...finalCrumbs] : [];
}

export function appendBreadcrumbs(breadcrumbs: BreadCrumbItem[]) {
  const finalCrumbs = breadcrumbs.reduce(
    (accumulator, currentCrumb, index) => [
      ...accumulator,
      {
        name: currentCrumb.title,
        item: [accumulator[index].item, currentCrumb.url.current]
          .join("/")
          .replaceAll("//", "/"),
      },
    ],
    [rootItem],
  );

  return finalCrumbs.length > 1 ? finalCrumbs : [];
}
